(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// ----------- Native javascript ----------- //
// legacy browser alert
var ua = navigator.userAgent;

if (ua.indexOf("MSIE 6.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else if (ua.indexOf("MSIE 7.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else if (ua.indexOf("MSIE 8.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else if (ua.indexOf("MSIE 9.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else if (ua.indexOf("MSIE 10.0") != -1) {
  alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
} else {} // ----------- jQuery ---------------------- //


$(function () {
  // drawer nav
  $("#hmbg").on('click', function () {
    $("#overlay").fadeIn();
    $("#drawer").addClass('__active');
  });
  $("#overlay,#close,#bnr_btn").on('click', function () {
    $("#drawer").removeClass('__active');
    $("#overlay").fadeOut();
  }); // header effect

  var startPos = 0;
  var headerElem = $('#fixed-header');
  var threshold = 0;
  $(window).on('scroll', function () {
    var currentPos = $(this).scrollTop();

    if (currentPos > startPos) {
      if ($(window).scrollTop() >= threshold) {
        headerElem.css({
          'transform': 'translateY(0)'
        });
      }
    } else {
      headerElem.css({
        'transform': 'translateY(-80px)'
      });
    }

    startPos = currentPos;

    if (currentPos === 0) {
      headerElem.css({
        'transform': 'translateY(0)'
      });
    }
  }); // btn hover 

  $('.hover_btn').each(function () {
    var imgSrc = $(this).attr('src');
    var hovImgSrc = $(this).attr('data-hover');
    $(this).hover(function () {
      $(this).attr('src', hovImgSrc);
    }, function () {
      $(this).attr('src', imgSrc);
    });
  }); // anchor scroll

  $('a[href*="#"].anchor-link').on('click', function () {
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  }); // fixed cv

  var threshold = 300;
  var target = $('#fixed_cv');
  target.css({
    'transform': 'translateY(160px)'
  });
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > threshold) {
      target.css({
        'transform': 'translateY(0)'
      });
    } else {
      target.css({
        'transform': 'translateY(160px)'
      });
    }
  }); // acordion panel

  $(".o-toggle-trig").each(function () {
    $(this).on('click', function () {
      $(this).toggleClass('__active');
      $(this).next().slideToggle();
    });
  }); // acordion panel

  var obj = $(".scroll-animation-obj");
  var hopIn = $(".scroll-animation-hop");
  $(window).on('scroll', function () {
    obj.each(function () {
      var objPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowH = $(window).height();

      if (scroll > objPos - windowH) {
        $(this).css({
          'opacity': '1'
        });
      } else {
        $(this).css({
          'opacity': '0'
        });
      }
    });
    hopIn.each(function () {
      var objPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowH = $(window).height();

      if (scroll > objPos - windowH) {
        $(this).css({
          'transform': 'translate(0,0)'
        });
      } else {
        $(this).css({
          'transform': 'translate(0,60px)'
        });
      }
    });
  });
});

},{}]},{},[1]);
